import { defineStore } from 'pinia';

export default defineStore('egpmBrandResultStore', {
    state: () => ({
        sreen: {},// 筛选参数对象
        searchEvent: null,// 搜索事件
    }),
    actions: {
        // 设置搜索事件
        setSearchEvent(event) {
            this.searchEvent = event;
        },
        // 触发搜索事件-用于主组件
        handleSearch(sreen) {
            this.sreen = sreen;
            this.searchEvent && this.searchEvent(this.sreen);
        },
        // 设置筛选参数对象
        setSreen(sreen = {}) {
            this.sreen = sreen;
        }
    },
    persist: {
        enabled: false,// 刷新页面后是否缓存
        strategies: [
            {
                storage: localStorage
            }
        ]
    }
})